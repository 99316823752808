import * as React from "react";

import Layout from "@layouts/Layout";
import Seo from "@components/Seo";
import { Typography } from "@components/atoms";

import info from "@constants/branding";

const TermsOfServicePage: React.FC = () => {
  return (
    <Layout>
      <Seo title="Terms of Service" />
      <Typography align="center" variant="h1">
        Terms of Service
      </Typography>

      <Typography variant="h3">Introduction</Typography>
      <Typography variant="body">
        {`These Website Standard Terms and Conditions written on this webpage
        shall manage your use of our website, ${info.title} accessible at
        www.${info.domain}.`}
      </Typography>
      <Typography variant="body">
        These Terms will be applied fully and affect your use of this Website.
        By using this Website, you agreed to accept all terms and conditions
        written in here. You must not use this Website if you disagree with any
        of these Website Standard Terms and Conditions.
      </Typography>
      <Typography variant="body">
        Minors or people below 18 years old are not allowed to use this Website.
      </Typography>

      <Typography variant="h3">Purpose</Typography>
      <Typography variant="body">
        {`This website is an area where ${info.domain}, its owners, visitors, and
        its collaborators can share information, stories, experiences, and
        research with other professionals worldwide. However, at times we may
        collaborate with credentialed partners who do have professional
        qualifications. These instances will be cited in any content where their
        expertise are utilized. If there is no mention of a collaborating expert
        on a piece of content on our site, you should assume the content
        contains our opinions and are for entertainment purposes only.`}
      </Typography>
      <Typography variant="body">
        The general information provided on this Website is not a substitute for
        the diagnosis, prognosis, treatment, prescription, or formal and
        individualized advice of a medical professional, professional trainer,
        qualified mechanic, or other qualified professional. Always seek the
        advice of a doctor or other qualified professional with any questions
        you may have regarding your health or exercise routines. Never disregard
        professional advice or delay in seeking it because of something you have
        read on this Website.
      </Typography>
      <Typography variant="body">
        {`By using this website you agree that you will not hold ${info.title}, its
        owners, or contributors responsible for actions you take, or fail to
        take, as a result of the information provided on this website.`}
      </Typography>

      <Typography variant="h3">Intellectual Property Rights</Typography>
      <Typography variant="body">
        {`Other than the content you own, under these Terms, ${info.domain} and/or
        its licensors own all the intellectual property rights and materials
        contained in this Website.`}
      </Typography>
      <Typography variant="body">
        You are granted limited license only for purposes of viewing the
        material contained on this Website.
      </Typography>
      <Typography variant="body">
        {`To the best of our knowledge, any materials or intellectual property
        presented here are original and do not infringe upon the intellectual
        property rights of others. If you believe that something presented here
        is infringing on the intellectual property rights of another person or
        entity, please contact us at info@${info.domain}.`}
      </Typography>

      <Typography variant="h3">Fair Use</Typography>
      <Typography variant="body">
        {`This website occasionally uses media under the Fair Use Doctrine (17
        U.S.C. § 107). Neither ${info.domain} nor it’s owner(s) claim ownership
        of media used in this way and it is displayed strictly under the
        guidelines and established case law relating to the Fair Use Doctrine.`}
      </Typography>

      <Typography variant="body">
        Copyright Disclaimer under section 107 of the Copyright Act 1976,
        allowance is made for “fair use” for purposes such as criticism,
        comment, news reporting, teaching, scholarship, education and research.
      </Typography>
      <Typography variant="body">
        Fair use is a use permitted by copyright statute that might otherwise be
        infringing.
      </Typography>
      <Typography variant="body">
        Fair use is a doctrine in the United States copyright law that allows
        limited use of copyrighted material without requiring permission from
        the rights holders, such as for commentary, criticism, news reporting,
        research, teaching or scholarship. It provides for the legal,
        non-licensed citation or incorporation of copyrighted material in
        another author’s work under a four-factor balancing test.
      </Typography>

      <Typography variant="h3">Restrictions</Typography>
      <Typography variant="body">
        You are specifically restricted from all of the following:
      </Typography>
      <ul>
        <li> publishing any Website material in any other media;</li>
        <li>
          selling, sublicensing and/or otherwise commercializing any Website
          material;
        </li>
        <li>publicly performing and/or showing any Website material;</li>
        <li>
          using this Website in any way that is or may be damaging to this
          Website;
        </li>
        <li>
          using this Website in any way that impacts user access to this
          Website;
        </li>
        <li>
          using this Website contrary to applicable laws and regulations, or in
          any way may cause harm to the Website, or to any person or business
          entity;
        </li>
        <li>
          engaging in any data mining, data harvesting, data extracting or any
          other similar activity in relation to this Website;
        </li>
        <li>using this Website to engage in any advertising or marketing.</li>
      </ul>

      <Typography variant="body">
        {`Certain areas of this Website are restricted from being access by you
        and ${info.domain} may further restrict access by you to any areas of
        this Website, at any time, in absolute discretion. Any user ID and
        password you may have for this Website are confidential and you must
        maintain confidentiality as well.`}
      </Typography>
      <Typography variant="h3">Your Content</Typography>
      <Typography variant="body">
        {`In these Website Standard Terms and Conditions, “Your Content” shall
        mean any audio, video text, images or other material you choose to
        display on this Website. By displaying Your Content, you grant
        ${info.domain} a non-exclusive, worldwide irrevocable, sub-licensable
        license to use, reproduce, adapt, publish, translate and distribute it
        in any and all media.`}
      </Typography>
      <Typography variant="body">
        {`Your Content must be your own and must not be invading any third-party’s
        rights. ${info.domain} reserves the right to remove any of Your Content
        from this Website at any time without notice.`}
      </Typography>

      <Typography variant="h3">No Warranties</Typography>
      <Typography variant="body">
        {`This Website is provided “as is,” with all faults, and ${info.domain}
        expresses no representations or warranties, of any kind related to this
        Website or the materials contained on this Website. Also, nothing
        contained on this Website shall be interpreted as advising you.`}
      </Typography>

      <Typography variant="h3">Limitation of Liability</Typography>
      <Typography variant="body">
        {`In no event shall ${info.domain}, nor any of its officers, directors and
        employees, shall be held liable for anything arising out of or in any
        way connected with your use of this Website whether such liability is
        under contract. ${info.domain}, including its owners, officers,
        directors and employees shall not be held liable for any indirect,
        consequential or special liability arising out of or in any way related
        to your use of this Website or the information contained therein.`}
      </Typography>

      <Typography variant="h3">Indemnification</Typography>
      <Typography variant="body">
        {`You hereby indemnify to the fullest extent ${info.domain} from and
        against any and/or all liabilities, costs, demands, causes of action,
        damages and expenses arising in any way related to your breach of any of
        the provisions of these Terms.`}
      </Typography>

      <Typography variant="h3">Severability</Typography>
      <Typography variant="body">
        If any provision of these Terms is found to be invalid under any
        applicable law, such provisions shall be deleted without affecting the
        remaining provisions herein.
      </Typography>

      <Typography variant="h3">Complaints and Retractions</Typography>
      <Typography variant="body">
        {`If you find material published on this website that you believe is of
        any concern including, but not limited to, material that is inaccurate
        or misleading, defamatory, published without necessary licenses or
        consent, or infringing upon the rights of another, please contact us at
        info@${info.domain}. Complaints will be addressed on a case-by-case
        basis.`}
      </Typography>

      <Typography variant="h3">Variation of Terms</Typography>
      <Typography variant="body">
        {`${info.domain} is permitted to revise these Terms at any time as it sees
        fit, and by using this Website you are expected to review these Terms on
        a regular basis.`}
      </Typography>

      <Typography variant="h3">Assignment</Typography>
      <Typography variant="body">
        {`${info.domain} is allowed to assign, transfer, and subcontract its
        rights and/or obligations under these Terms without any notification.
        However, you are not allowed to assign, transfer, or subcontract any of
        your rights and/or obligations under these Terms.`}
      </Typography>

      <Typography variant="h3">Entire Agreement</Typography>
      <Typography variant="body">
        {`These Terms constitute the entire agreement between ${info.domain} and
        you in relation to your use of this Website, and supersede all prior
        agreements and understandings.`}
      </Typography>

      <Typography variant="h3">Governing Law & Jurisdiction</Typography>
      <Typography variant="body">
        These Terms will be governed by and interpreted in accordance with the
        laws of the State of California, United States and you submit to the
        non-exclusive jurisdiction of the state and federal courts located in
        the State of California, United States for the resolution of any
        disputes.
      </Typography>
    </Layout>
  );
};

export default TermsOfServicePage;
